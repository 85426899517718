import react from "../assets/img/reactLogo.png";
import html from "../assets/img/html5.png";
import css from "../assets/img/css3.png";
import figma from "../assets/img/figmaLogo.png";
import wordpress from "../assets/img/WordpressLogo.png";
import woocomerce from "../assets/img/woocomerceLogo.png";
import seo from "../assets/img/seoLogo.png";

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import arrow1 from "../assets/img/arrow1.svg";
import arrow2 from "../assets/img/arrow2.svg";
import colorSharp from "../assets/img/color-sharp.png"


export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="skill" id="skills">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="skill-bx wow zoomIn">
                        <h2>Habilidades</h2>
                        <p>Desarrollador y diseñador web Full Stack con un enfoque audaz.
                          Mi enfoque integral garantiza resultados sorprendentes,
                          desde la creación de experiencias interactivas hasta el diseño de
                          interfaces cautivadoras. Con experiencia en plataformas populares como WordPress y WooCommerce,
                          junto con sólidos conocimientos en SEO y estrategias de marketing digital.</p>
                        <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
                        {/* <div className="item" style={{ width: '100%',marginTop: '40px',marginLeft: '20px'}}> */}

                            <div className="item" style={{ width: '100%',marginTop: '40px',marginLeft: '20px'}}>
                                <img src={react} alt="Image" />
                                <h5>React</h5>
                            </div>
                            <div className="item">
                                <img src={html} alt="Image" />
                                <h5>HTML 5</h5>
                            </div>
                            <div className="item">
                                <img src={css} alt="Image" />
                                <h5>CSS 3</h5>
                            </div>
                            <div className="item">
                                <img src={figma} alt="Image"/>
                                <h5>Diseño de Interfaz con Figma </h5>
                            </div>
                            <div className="item" style={{marginTop: '50px'}}>
                                <img src={wordpress} alt="Image"/>
                                <h5>WordPress</h5>
                            </div>
                            <div className="item" style={{marginTop: '50px'}}>
                                <img src={woocomerce} alt="Image"/>
                                <h5  style={{marginTop: '50px'}}>WooComerce</h5>
                            </div>
                            <div className="item" >
                                <img src={seo} alt="Image"/>
                                <h5>SEO & Marketing Digital</h5>
                            </div>
                        </Carousel>
                        <p>¿ Listo para impulsar tu presencia en línea hacia nuevos horizontes ?</p>
                    </div>
                </div>
            </div>
        </div>
        <img className="background-image-left" src={colorSharp} alt="Image" />
    </section>
  )
}

import {MailchimpForm} from "./MailchimpForm";
import logo from "../assets/img/LogosVSI.png";
import navIcon1 from '../assets/img/nav-icon1.svg';
import navIcon2 from '../assets/img/gitHub.png';
import navIcon3 from '../assets/img/nav-icon3.svg';
import { Container, Row, Col} from "react-bootstrap";

export const Footer = () => {
    return (
        <footer className="footer">
            <Container>
                <Row className="align-item-center">
                    {/* Contiene nuestra futura Newsletter */}
                    {/* <MailchimpForm/> */}
                    <Col sm={6}>
                        <img src={logo} alt="Logo"/>
                    </Col>
                    <Col sm={6} className="text-center text-sm-end">
                        <div className="social-icon">
                            <a href="https://www.linkedin.com/in/victor-s%C3%A1nchez-i%C3%B1iguez-b0849a224/" target="_blank"><img src={navIcon1} alt="Perfil de Linkedin de Victor Sanchez Iñiguez"/></a>
                            <a href="https://github.com/VictorSanchezDev22" target="_blank"><img src={navIcon2} alt="Perfil de Git Hub de Victor Sanchez"/></a>
                            {/* <a href="#"><img src={navIcon3} alt=""/></a> */}
                        </div>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}
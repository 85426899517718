import projImg1 from '../assets/img/finlookerPhoto.png';
import projImg2 from '../assets/img/terapeutaJose.png';
import projImg3 from '../assets/img/vocoinfoFoto.png';
import projImg4 from '../assets/img/SEO2018-2020_filosofiaEZ.png';
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import 'animate.css';

import colorSharp2 from "../assets/img/color-sharp2.png";
import TrackVisibility from 'react-on-screen';

export const Projects = () => {
    const projects = [
        {
          title: "Finlooker Financial App",
          description: "Desarrollo Full Stack de algunas funcionalidades y otras partes del Front End",
          imgUrl: projImg1,
          web : "https://www.finlooker.com/",
        },
        {
          title: "Pagina de Negocio Personal, Terapeuta",
          description: "Diseño & Desarrollo , además de la gestión de dominio y mail de contacto automatizado",
          imgUrl: projImg2,
          web : "https://terapeutaintervencionista.com/",
        },
        {
          title: "Formulario de Inscripción Expodental Madrid",
          description: "Creación y desarrollo de un Formulario Web para Voco. Para facilitar la inscripción a los cursos de la Expodental 2024, conectado con una hoja de Excel",
          imgUrl: projImg3,
          web : "https://vocoinfo.com/",
        },
        {
          title: "Diseño Wordpress y Posicionamiento SEO",
          description: "Web posicionada con SEO, redacción de contenidos, Copywriting, más de 30.000 visitas. No cuento con el dominio pero se pueden ver los graficos de Google Analytics",
          imgUrl: projImg4,
          web : "https://victorsanchezdev.tech/png/SEO2018-2020_filosofiaEZ.png",
        }
      ];

    return (
        <section className="project" id="projects">
            <Container>
                <Row>
                <Col size={12}>
                    <TrackVisibility>
                    {({ isVisible }) =>
                    <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                        <h2>Proyectos</h2>
                        <p>Durante los últimos tres años, he tenido el privilegio de sumergirme en el emocionante mundo del desarrollo web y diseño. Cada proyecto ha sido una oportunidad única para aprender, crecer y perfeccionar mis habilidades.
</p>
                        <Tab.Container id="projects-tabs" defaultActiveKey="first">
                        {/* <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                            <Nav.Item>
                            <Nav.Link eventKey="first">Tab 1</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                            <Nav.Link eventKey="second">Tab 2</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                            <Nav.Link eventKey="third">Tab 3</Nav.Link>
                            </Nav.Item>
                        </Nav> */}
                        <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                            <Tab.Pane eventKey="first">
                            <Row>
                                {
                                projects.map((project, index) => {
                                    return (
                                    <ProjectCard
                                        key={index}
                                        {...project}
                                        />
                                    )
                                })
                                }
                            </Row>
                            </Tab.Pane>
                            {/* <Tab.Pane eventKey="section">
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque quam, quod neque provident velit, rem explicabo excepturi id illo molestiae blanditiis, eligendi dicta officiis asperiores delectus quasi inventore debitis quo.</p>
                            </Tab.Pane>
                            <Tab.Pane eventKey="third">
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque quam, quod neque provident velit, rem explicabo excepturi id illo molestiae blanditiis, eligendi dicta officiis asperiores delectus quasi inventore debitis quo.</p>
                            </Tab.Pane> */}
                        </Tab.Content>
                        </Tab.Container>
                    </div>}
                    </TrackVisibility>
                </Col>
                </Row>
            </Container>
            <img className="background-image-right" src={colorSharp2}></img>
        </section>
    )
}
import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

import { Container, Row, Col } from "react-bootstrap";
import contactImg from "../assets/img/contact-img.svg";
import TrackVisibility from 'react-on-screen';


export const Contacto = () => {
  const form = useRef();
  const [emailSent, setEmailSent] = useState(false); // Estado para controlar si se ha enviado el correo electrónico con éxito

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_7u6px0t', 'template_cvgbfra', form.current, {
        publicKey: 'oLyP6tWbWTdWexgqk',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          setEmailSent(true); // Establecer el estado de correo electrónico enviado como verdadero
        },
        (error) => {
          console.log('FAILED...', error.text);
        }
      );
  };

  return (
    <section className="contact" id="connect">
       <Container>
        <Row className="align-items-center">
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <img className={isVisible ? "animate__animated animate__zoomIn" : ""} src={contactImg} alt="Contact Us"/>
              }
            </TrackVisibility>
          </Col>
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <h2>Contacta con nosotros</h2>
                {emailSent ? ( // Si el correo electrónico se ha enviado con éxito, mostrar el mensaje
                <p>¡Correo electrónico enviado!</p>
                ) : ( 
                <form ref={form} onSubmit={sendEmail}>
                  <Row>
                    <Col size={12} sm={6} className="px-1">
                      <input type="text" name="user_name" placeholder="Nombre" />
                    </Col>
                    {/* <Col size={12} sm={6} className="px-1">
                      <input type="text" value={formDetails.lasttName} placeholder="Last Name" onChange={(e) => onFormUpdate('lastName', e.target.value)}/>
                    </Col> */}
                    <Col size={12} sm={6} className="px-1">
                      <input type="email" name="user_email" placeholder="Email Address"/>
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="tel"  placeholder="Phone No."/>
                    </Col>
                    <Col size={12} className="px-1">
                      <textarea rows="6" name="message" placeholder="Mensaje" ></textarea>
                      <button type="submit" value="Send"><span>Envia</span></button>
                    </Col>
                  </Row>
                </form>
                )}
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

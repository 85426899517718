import './App.css';
import {NavBar} from './components/Navbar';
import {Banner} from './components/Banner';
import {Skills} from './components/Skills';
import {Projects} from './components/Projects';
import 'bootstrap/dist/css/bootstrap.min.css'; 
// import { Contact } from './components/Contact';
import {Contacto} from './components/ContactoVsi';
import { Footer } from './components/Footer';

function App() {
  return (
    <div className="App">
       <NavBar/>
       <Banner/>
       <Skills/>
       <Projects/>
       <Contacto/>
       {/* <Contact/> */}
       <Footer/>
    </div>
  );
}

export default App;

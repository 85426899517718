import { Col, Container, Tab } from "react-bootstrap"; 
import { TypeUnderline } from "react-bootstrap-icons";

export const ProjectCard = ({title, description, imgUrl, web, altTxt}) => {
    return (
        <Col sm={6} md={4}>
            <a href={web} style={{color:'inherit'}} target="_blank">
            <div className="proj-imgbx">
                <img src={imgUrl} alt={altTxt}/>
                <div className="proj-txtx">
                    <h4>{title}</h4>
                    <span>{description}</span>
                </div>
            </div></a>
        </Col>
    )
}
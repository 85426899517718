import { useState, useEffect } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { ArrowRightCircle } from "react-bootstrap-icons"
// import headerImg from "../assets/img/header-img.svg"
import { HashLink } from 'react-router-hash-link';
import {
  BrowserRouter as Router
} from "react-router-dom";
import profile from '../assets/img/victorProfile.jpg';

export const Banner = () => {
    const [loopNum , setLoopNum] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);
    const toRotate = ["Desarrollador Full Stack", "Diseñador Web"]; 
    const [text, setText] = useState (''); 
    const [delta, setDelta]= useState(300 - Math.random() *100);
    const period = 500;

    useEffect (() =>{
        let ticker = setInterval (() => {
            tick();
        },delta)

        return () => {clearInterval(ticker)};
    }, [text])

    const tick = () =>{
        let i = loopNum % toRotate.length; 
        let fullText = toRotate[i];
        let updatedText = isDeleting ? fullText.substring(0, text.length -1) : fullText.substring(0, text.length +1);

        setText(updatedText); 

        if (isDeleting){
            setDelta(prevDelta => prevDelta/2)
        }

        if(!isDeleting && updatedText === fullText){
            setIsDeleting(true); 
            setDelta(period);
        }else if (isDeleting && updatedText === ''){
            setIsDeleting(false); 
            setLoopNum(loopNum+1); 
            setDelta(500); 
        }
    }
    return (
        <Router>
        <section className="banner" id="home">
            <Container>
                <Row className="align-items-center justify-content-center">
                    <Col xs={12} md={6} xl>
                        <span className="tagline">Víctor Sánchez</span>
                        <h1><span className="wrap">{text}</span></h1>
                        <p>También tengo experiencia en optimización para motores de búsqueda (SEO), 
                        lo que me permite aumentar la visibilidad y el alcance en línea de tu negocio.
                        </p>
                        <HashLink to="#connect">
                            <button onClick={() => console.log('connect')}>Contactemos <ArrowRightCircle size={25} /></button>
                        </HashLink>                
                    </Col>
                    <Col xs={12} md={6} xl>
                        <img src={profile} alt="Header Img" className="img-fluid" />
                    </Col>
                </Row>
            </Container>
        </section>
        </Router>

    )  
}